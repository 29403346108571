import * as React from "react";
import {Route, Switch} from "react-router-dom";
import Playlist from "./Page/Playlist/PlaylistContainer";
import AppContext from "../Context/app-context";
import Detail from "./Page/Detail/DetailContainer";
import Login from "./Page/Login/LoginContainer";
import Contact from "./Page/Contact/ContactContainer";
import Founders from "./Page/Founders/FoundersCountainer";

export default class Routes extends React.Component {
    static contextType = AppContext;

    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route path="/playlist" exact component={Playlist}/>
                <Route path="/playlist/:id" component={Detail}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/founders" component={Founders}/>
            </Switch>
        );
    }
}
