import * as React from "react";
import "./UserProfile.css";
import UserDownArrowIcon from '../../Assets/images/user-down-arrow.svg';
import UserIcon from '../../Assets/images/user-thumb.png';
import YogoIcon from '../../Assets/images/yogamusiq.svg';
import clsx from "clsx";
import UserModel from "../Models/UserModel";

interface UserProfileProps {
    user: UserModel,
    logout: () => {},
    open: boolean,
    openModel: any,
}

const UserProfile: React.FC<UserProfileProps> = ({user, logout, open, openModel}) => {
    let image = YogoIcon;
    if (Object.keys(user).length > 0 && user.images !== undefined && user.images[0] !== undefined) {
        image = user.images[0].url;
    }
    return <div className="userProfile">
        <a onClick={openModel} id="profile">
                <span className="userThumb">
                    <img src={image} alt={user.display_name}/>
                </span>
            <span className="userName">
                    {user.display_name}
                </span>
            <span className="downArrow">
                    <img src={UserDownArrowIcon} alt="Icon"/>
                </span>
        </a>
        <ul id="profileDropdown" className={clsx({profileDropdown: true, open: open})}>
            <li>
                <a onClick={logout}>Logout</a>
            </li>
        </ul>
    </div>;
};

export default UserProfile;
