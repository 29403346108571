import * as React from "react";
import "./Header.css";
import Logo from '../../Assets/images/logo.svg';
import MenuIcon from '../../Assets/images/menu-icon.svg';
import CloseIcon from '../../Assets/images/close-icon.svg';
import DropDownArrowIcon from '../../Assets/images/dropdown-arrow.svg';
import {Link} from "react-router-dom";
import UserProfile from "./UserProfileContainer";
import clsx from "clsx";

interface HeaderProps {
    openModel: boolean,
    modelOpen: () => void
}

const Header: React.FC<HeaderProps> = ({openModel, modelOpen}) => {
    return <header className="header">
        <div className="wrapper">
            <div className="headerInner">
                <div className="logo">
                    <Link to={"/"}>
                        <img src={Logo} alt="Logo"/>
                    </Link>
                </div>
                <div className="menuRow">
                    <div className="mobileMenuBtn">
                        <button type="button" id="menuBtn" onClick={() => modelOpen()}>
                            <img src={MenuIcon} className="menuIcon" alt="Menu Icon"/>
                            <img src={CloseIcon} className="closeIcon" alt="Close Icon"/>
                        </button>
                    </div>
                    <ul id="mobileMenu" className={clsx({'menuLinkRow': true, 'open': openModel})}>
                        <li><a>App</a></li>
                        <li><a>Shop</a></li>
                        <li className="dropdown-menu">
                            <a>
                                About
                                <span className="downArrow">
                                    <img src={DropDownArrowIcon} alt="Icon"/>
                                </span>
                            </a>
                            <ul className="subMenu">
                                <li><Link to="/contact">Contact</Link></li>
                                <li>Team</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <UserProfile/>
            </div>
        </div>
    </header>;
};

export default Header;