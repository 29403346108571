import * as React from "react";
import "../../Assets/css/ribbon.css";
import "./Master.css";
import Header from "../Includes/HeaderContainer";
import Content from "../Page/ContentContainer";

const Master: React.FC = (props) => {
    return <div>
        <Header></Header>
        <Content></Content>
        {/*This footer needs to be placed somewhere else- I see issues with
        the footer floating around. commenting it out for now*/}
        {/*<Footer></Footer>*/}
        <div className="corner-ribbon bot-right orange" style={{zIndex: 99999999, backgroundColor: '#f76d48', fontFamily: 'Gilroy'}}>Beta</div>
    </div>;
};

export default Master;
